<template>
  <label for="spec-file-upload" class="custom-file-upload d-flex align-center justify-flex-start">
    <input
      id="spec-file-upload"
      type="file"
      hidden
      @change="inputFile"
    />
    <v-btn
      color="primary"
      small
      :loading="loading"
      style="pointer-events: none;"
    >上傳附件</v-btn>
    <div
      v-if="files && files.length > 0"
      style="border: solid 1px #999; border-radius: 5px; padding: 4px 5px; margin: 0 7px; flex: 1;"
    >
    {{ files[0].name }}</div>
  </label>
</template>

<script type="text/babel">
import formItemMixin from '@/components/form/mixins/formItemMixin'
export default {
  data: () => ({
    loading: false,
    
  }),
  mixins: [formItemMixin],
  computed: {
    files() {
      return this.formData.files
    }
  },
  methods: {
    async inputFile(file) {
      if (!file) return
      if (file.length === 0) return
      const fileOverSize = (size) => {
        const Number1MBSIZE = 100 * 1024 * 1024
        return size > Number1MBSIZE
      }
      const acceptType = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg']
      if(!acceptType.includes(file.target.files[0].type)){
        this.$snotify.warning('檔案類型不支援')
        return
      }
      if (fileOverSize(file.target.files[0].size)) {
        this.$snotify.warning('上傳檔案不得大於100MB')
        return
      }
      try {
        this.loading = true
        let formData = new FormData()
        formData.append('file', file.target.files[0])
        const res = await this.$api.collection.productApi.uploadFile(formData, file.target.files[0].name)
        this.formData.files = [{
          name: res.name,
          url: res.url
        }]
        this.loading = false
      } catch (err) {
        console.log(err)
        this.loading = false
      }
    },
    uploadFile() {
      this.loading = true
      
    }
  }
}
</script>